
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        












































































































































































@import '@/styles/_colors.scss';

.modal__textarea {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.modal__main--flex {
  display: flex;
  flex-direction: row;
}

.modal__main--input-value {
  width: 75px;
  border: 1px solid var(--color-primary) !important;
}

.modal__main--input-label {
  margin-right: 1rem;
}

.modal__main--btn {
  align-self: flex-end;
  margin-bottom: 1.5rem;
}

.btn--space {
  margin-left: 1rem;
}

.btn--close {
  height: 3rem;
}

.sheets-info {
  display: flex;
  padding: 1rem;
  font-size: 0.9rem;
  background: rgba($color-yellow, 0.2);
  border-radius: 3px;
}

.sheets-info__icon {
  margin-top: 0.2rem;
  margin-right: 0.5rem;
}

strong {
  font-weight: 500;
}
